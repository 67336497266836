import React from 'react'
import "twin.macro";
import Passion from 'src/images/Passion.jpg'
export default function ContactHero() {
    return (
        <div tw="py-8 flex bg-white w-full justify-center items-center">
        <div tw="relative bg-gray-800 rounded-xl">
        <div tw="h-56 bg-secondary-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 rounded-xl overflow-hidden">
        <img tw="w-full h-full object-cover" src={Passion} alt="" />
        </div>
        <div tw="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div tw="md:ml-auto md:w-1/2 md:pl-10">


            <div tw="">
                <h2 tw="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">CONTACT US</h2>
                <p tw="mt-3 text-sm leading-6 text-white">Thank you for visiting our site, SickMarketing. We are a full-service marketing firm with a passion for a radical open mind and radical transparency. No surprises, no gimmicks, just the bottom line.<br /><br />If you are interested in any of our services or would like to schedule a consultation, please contact us on the contact form.<br /><br />Quickest replies by email, please include as much information as possible regarding your project.</p>
                <dl tw="mt-8 text-base text-gray-300">
                    <div>
                    <dt tw="sr-only">Postal address</dt>
                    <dd>
                        <p>SICKMKTG<br /></p>
                        <p>Newport Beach, CA 92660</p>
                    </dd>
                    </div>
                    <div tw="mt-6">
                    <dt tw="sr-only">Work Timing</dt>
                    <dd>
                        <p><strong>Mon. – Fri:</strong>  8:30 AM – 5:00 PM<br /></p>
                        <p><strong>Sat. – Sun:</strong>  Closed</p>
                    </dd>
                    </div>

                </dl>
                </div>
        </div>
        
        </div>


    </div>
    </div>
    )
}
